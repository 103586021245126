<template>
  <v-app>
    <v-app-bar
      app
      clipped-left
      dark
      class="primary"
    >
      <router-link to="/">
        <v-avatar>
          <v-icon class="white--text">
            mdi-basket
          </v-icon>
        </v-avatar>
      </router-link>
      <router-link to="/">
        <v-toolbar-title class="white--text">
          Taobao List
        </v-toolbar-title>
      </router-link>
      <v-spacer />
      <v-btn
        to="/wishlist"
        icon
      >
        <v-icon>
          mdi-bookmark
        </v-icon>
      </v-btn>
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
    </v-app-bar>
    <v-navigation-drawer
      permanent
      app
      clipped
      left
      floating
      class="secondary"
    >
      <v-list-item-group color="primary">
        <v-list>
          <v-list-item
            v-for="nav in lists"
            :key="nav.slug"
            :to="'/list/' + nav.slug"
          >
            <v-list-item-content>
              <v-list-item-title class="text-right">
                {{ nav.month }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-list-item-group>
    </v-navigation-drawer>
    <v-main class="deep-orange lighten-5">
      <v-container>
        <v-row v-if="$route.name === 'list'">
          <v-col v-if="currentMonth.month.length">
            <v-data-table
              :headers="dataTableHeaders"
              :items="currentMonth.data"
              v-if="currentMonth.data"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                  class="pt-4"
                >
                  <v-text-field
                    label="Search..."
                    dense
                  />
                  <v-spacer />
                  <v-btn
                    dark
                    class="primary mb-3"
                    :to="'/edit/' + $route.params.id"
                  >
                    Edit
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.url="{ item }">
                <span
                  class="urltohref"
                  v-html="urlToHref(item)"
                />
              </template>
              <template v-slot:item.price="{ item }">
                {{ '¥' + item.price }}
              </template>
              <template v-slot:item.status="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.status }}

                      <v-icon right>
                        mdi-menu-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="status in dataTableStatus"
                      :key="status"
                      @click="item.status = status"
                    >
                      <v-list-item-title>{{ status }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:item.checkbox="{ item }">
                <v-simple-checkbox
                  color="primary"
                  v-model="item.checkbox"
                />
              </template>
            </v-data-table>
            <div class="text-right">
              Total: {{ '¥' + sumOfMonth }}
            </div>
          </v-col>
          <v-col v-else>
            <v-icon>
              mdi-loading mdi-spin
            </v-icon>
          </v-col>
        </v-row>

        <v-row v-if="$route.name === 'edit'">
          <v-col>
            <v-simple-table v-if="currentMonth">
              <template v-slot:top>
                <v-toolbar
                  class="text-right"
                  flat
                >
                  <v-spacer />
                  <v-btn
                    class="primary"
                    dark
                    @click="updateLists()"
                  >
                    Done
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>
                      Url
                    </th>
                    <th>
                      Option
                    </th>
                    <th>
                      Price
                    </th>
                    <th>
                      Shipping
                    </th>
                    <th>
                      Quantity
                    </th>
                    <th>
                      Others
                    </th>
                    <th>
                      Keywords
                    </th>
                    <th>
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, index) in currentMonth.data"
                    :key="row.url"
                  >
                    <td>
                      <v-text-field v-model="row.url" />
                    </td>
                    <td>
                      <v-text-field v-model="row.option" />
                    </td>
                    <td>
                      <v-text-field v-model="row.price" />
                    </td>
                    <td>
                      <v-text-field v-model="row.shipping" />
                    </td>
                    <td>
                      <v-text-field v-model="row.quantity" />
                    </td>
                    <td>
                      <v-textarea
                        v-model="row.others"
                        rows="1"
                      />
                    </td>
                    <td>
                      <v-text-field v-model="row.keywords" />
                    </td>
                    <td>
                      <v-btn
                        icon
                        @click="$store.dispatch('cmSpliceCurrentMonth', index)"
                      >
                        <v-icon class="primary--text">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="1000"
                      class="text-center cursor-pointer"
                      @click="cmAddNewRow()"
                    >
                      <v-icon>
                        mdi-plus
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row
          v-if="$route.name === 'wishlist'"
          class="justify-center"
        >
          <v-col md="8">
            <v-card>
              <v-card-title>
                Wishlist
              </v-card-title>
              <v-card-text>
                <v-row
                  v-for="(wish, index) in wishlist"
                  :key="index + wish.text"
                >
                  <v-checkbox
                    v-model="wish.check"
                    :label="wish.text"
                    hide-details
                    class="shrink dense mt-0 px-3"
                  />
                  <v-spacer />
                  <v-icon
                    class="mr-3"
                    @click="removeWishRow(index)"
                  >
                    mdi-close
                  </v-icon>
                </v-row>
                <v-row>
                  <v-checkbox
                    disabled
                    hide-details
                    class="ml-3 mr-1 mt-0"
                  />
                  <v-text-field
                    autofocus
                    v-model="newWish"
                    dense
                    class="pr-3"
                    @keyup.enter="appendNewWish"
                  />
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row
          v-if="$route.name === '404'"
          class="justify-center align-center"
        >
          <p class="text-h3 mt-8">
            404 Not Found
          </p>
        </v-row>
      </v-container>
      <v-btn
        fixed
        left
        bottom
        fab
      >
        <v-icon
          class="primary--text"
          @click="newMonthDialog = true;"
        >
          mdi-plus
        </v-icon>
      </v-btn>
    </v-main>
    <v-dialog
      v-model="newMonthDialog"
      width="500"
    >
      <v-card>
        <v-card-title>Create New Month</v-card-title>
        <v-card-text>
          <v-text-field
            color="primary"
            v-model="newMonthName"
            label="Name"
            :rules="[() => !!newMonthName || 'This field is required']"
          />
          <v-text-field
            color="primary"
            v-model="newMonthSlug"
            label="Slug"
            :rules="[() => !!newMonthSlug || 'This field is required']"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="createNewMonth"
            class="primary"
            dark
            :disabled="!newMonthName || !newMonthSlug"
          >
            Create
          </v-btn>
          <v-btn @click="newMonthDialog = 0">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'App',
  data: () => ({
    dataTableHeaders: [{
      text: 'checkbox',
      value: 'checkbox'
    },
    {
      text: 'URL',
      value: 'url'
    },
    {
      text: 'Option',
      value: 'option'
    },
    {
      text: 'Price',
      value: 'price'
    },
    {
      text: 'Shipping',
      value: 'shipping'
    },
    {
      text: 'Quantity',
      value: 'quantity'
    },
    {
      text: 'Others',
      value: 'others'
    },
    {
      text: 'Keywords',
      value: 'keywords'
    },
    {
      text: 'Status',
      value: 'status'
    }
    ],
    dataTableStatus: [
      'Wait for purchase',
      'Out of Stock',
      'Fail to purchase',
      'Ordered',
      'Shipping (China)',
      'Shipping (HK)',
      'Arrived'
    ],
    serachInMonth: '',
    monthRotation: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
    newMonthDialog: false,
    newMonthName: '',
    newWish: ''
  }),
  methods: {
    urlToHref (item) {
      var shopType = ''
      if (item.url.includes('tmall')) {
        shopType = '<i aria-hidden="true" class="v-icon notranslate custom-tmall"></i>'
      } else if (item.url.includes('taobao')) {
        shopType = '<i aria-hidden="true" class="v-icon notranslate custom-taobao"></i>'
      } else if (item.url.includes('amazon')) {
        shopType = '<i aria-hidden="true" class="v-icon notranslate mdi mdi-amazon"></i>'
      } else {
        shopType = '<i aria-hidden="true" class="v-icon notranslate mdi mdi-link"></i>'
      }
      return '<a href="' + item.url + '" rel="noopner" target="_blank" class="text-decoration-none">' + shopType + '</a>'
    },
    updateLists () {
      axios.put('/lists', this.lists)
        .then()
        .catch()
        .finally(
          this.$router.push('/list/' + this.$route.params.id)
        )
    },
    appendNewWish () {
      var temp = {
        status: false,
        text: this.newWish
      }
      this.wishlist.push(temp)
      this.newWish = ''
      this.updateWishlist()
    },
    updateWishlist () {
      axios.put('/wishlist', this.wishlist)
        .then()
    },
    createNewMonth () {
      this.$store.commit('appendNewMonth', {
        name: this.newMonthName,
        slug: this.newMonthSlug
      })
      this.newMonthName = ''
      this.newMonthSlug = ''
      this.newMonthDialog = 0
      this.$router.push('/list/' + this.newMonthSlug)
      this.updateLists()
    },
    ...mapMutations([
      'appendNewRow',
      'removeWishRow',
      'removeRow'
    ]),
    ...mapActions([
      'fetchLists',
      'fetchWishlist',
      'cmAddNewRow'
    ])
  },
  computed: {
    newMonthSlug () {
      var temp = this.newMonthName.toLowerCase()
      temp = temp.replace(/ /g, '-')
      temp = temp.replace(/\//g, '\\')
      return temp
    },
    ...mapState([
      'lists',
      'wishlist'
    ]),
    ...mapGetters([
      'currentMonth',
      'sumOfMonth'
    ])
  }
}
</script>

<style>
[v-cloak]{
  display: none;
}

html, body{
  overflow-y: auto !important;
}

.urltohref a i:before{
  font-size: 150%;
}

.cursor-pointer{
  cursor: pointer
}

@font-face {
  font-family: 'custom-icon';
  src:  url('assets/fonts/icomoon/fonts/custom-icon.eot?5kais2');
  src:  url('assets/fonts/icomoon/fonts/custom-icon.eot?5kais2#iefix') format('embedded-opentype'),
    url('assets/fonts/icomoon/fonts/custom-icon.ttf?5kais2') format('truetype'),
    url('assets/fonts/icomoon/fonts/custom-icon.woff?5kais2') format('woff'),
    url('assets/fonts/icomoon/fonts/custom-icon.svg?5kais2#custom-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'custom-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-taobao:before {
  content: "\e900";
}
.custom-tmall:before {
  content: "\e901";
}

</style>
