import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home'
  },
  {
    path: '/list/:id',
    name: 'list'
  },
  {
    path: '/edit/:id',
    name: 'edit'
  },
  {
    path: '/wishlist',
    name: 'wishlist'
  },
  {
    path: '/*',
    name: '404'
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'home') {
    next('/list/latest')
  }

  if (!store.state.lists.length) {
    await store.dispatch('fetchLists')
    store.dispatch('fetchWishlist')
  }

  if (to.name === 'list' || to.name === 'edit') {
    store.commit('cloneParams', to)
    store.getters.currentMonth.slug !== '' ? next() : next('/404')
  } else {
    next()
  }
})

export default router
