import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    paramsID: '',
    wishlist: [],
    lists: []
  },
  mutations: {
    appendNewRow (state, cm) {
      var newRow = {
        url: '',
        option: '',
        price: '',
        shipping: '',
        quantity: '',
        others: '',
        keywords: '',
        status: 'Wait for purchase',
        checkbox: false
      }
      cm.data.push(newRow)
    },
    removeWishRow (state, i) {
      state.wishlist.splice(i, 1)
      this.$root.updateWishlist()
    },
    catchList (state, res) {
      state.lists = res.data
    },
    catchWishlist (state, res) {
      state.wishlist = res.data
    },
    cloneParams (state, to) {
      if (to.params.id === 'latest') {
        const l = state.lists.length - 1
        state.paramsID = state.lists[l].slug
      } else {
        state.paramsID = to.params.id
      }
    },
    appendNewMonth (state, {
      name,
      slug
    }) {
      var template = {
        month: name,
        slug: slug,
        data: []
      }
      state.lists.push(template)
    },
    spliceCurrentMonth (state, {
      cm,
      index
    }) {
      cm.data.splice(index, 1)
    }
  },
  actions: {
    fetchLists ({
      commit
    }) {
      return axios('/lists').then(
        res => {
          commit('catchList', res)
        }
      )
    },
    fetchWishlist ({
      commit
    }) {
      return axios('/wishlist').then(
        res => {
          commit('catchWishlist', res)
        }
      )
    },
    cmAddNewRow ({
      commit,
      getters
    }) {
      var cm = getters.currentMonth
      commit('appendNewRow', cm)
    },
    cmSpliceCurrentMonth ({
      commit,
      getters
    }, index) {
      var cm = getters.currentMonth
      commit('spliceCurrentMonth', {
        cm,
        index
      })
    }
  },
  getters: {
    currentMonth (state) {
      var template = {
        month: '',
        slug: '',
        data: []
      }
      var obj = template

      if (state.lists.length) {
        obj = state.lists.find(current => current.slug === state.paramsID)

        if (obj === undefined) {
          obj = template
        }
      }

      return obj
    },
    sumOfMonth (state, getters) {
      var temp = 0
      if (state.lists.length) {
        getters.currentMonth.data.forEach(
          row => {
            temp = temp + Number(row.price) * Number(row.quantity) + (row.shipping ? Number(row.shipping) : 0)
          }
        )
      }
      return temp.toFixed(2)
    }
  }
})
